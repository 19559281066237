<template>
    <div class="component-wrap">

        <div class="md-medium-hide main-hdr">

            <Search @setResultsOrders="setResultsOrders"
                    @searchInput="inputSearch" @loadingSearchResults="loadingSearchResults"/>

            <div v-if="!filteredOrders.length"
                 class="init-search-desktop md-layout md-alignment-center-center md-raised md-medium-hide">
                <div class="no-result-message md-layout-item md-size-70">
                    <p> Nothing here yet. <br>
                        Use search to load artwork and signage.
                    </p>
                </div>
            </div>
        </div>

        <div class="tablet-search-view">
            <div v-if="!showTabletTopPanel" v-touch:swipe.left="showTabletModal"
                 class="init-search-tablet md-layout md-alignment-center-center">
                <div v-if="!filteredOrders.length" class="no-result-message md-layout-item md-size-100">
                    <p> Nothing here yet. <br>
                        Use search to load artwork and signage.
                    </p>

                    <p>Swipe left search to search at any time </p>

                    <p> Or click the "Search" button below to start </p>
                </div>

                <md-button class="start-search-button md-primary md-raised" @click="showTabletTopPanel = true">
                    Start a Search
                </md-button>
            </div>

            <Search v-if="showTabletTopPanel"
                    @searchInput="inputSearch" @setResultsOrders="setResultsOrders"></Search>
        </div>

        <div class="init-search-mobile md-layout md-alignment-center-center">
            <div v-if="!filteredOrders.length" class="no-result-message md-layout-item md-size-100"
                 v-touch:swipe.left="showMobileSideSearch">
                <p> Nothing here yet. <br>
                    Use search to load artwork and signage.
                </p>

                <p>Swipe left search to search at any time </p>

                <p> Or click the "Search" button below to start </p>
            </div>

            <md-button class="start-search-button md-primary md-raised" @click="showSidePanel = true">
                Start a Search
            </md-button>

            <md-app-drawer class="md-right" v-show="showSidePanel" :md-active.sync="showSidePanel">
                <md-list>
                    <md-list-item>
                        <Search v-show="showSidePanel" @searchInput="inputSearch"
                                @setResultsOrders="setResultsOrders"></Search>
                    </md-list-item>

                </md-list>
            </md-app-drawer>
        </div>

        <installation-dashboard-table v-if="filteredOrders.length && !loadingSpinner"
                                      :installations-prop="filteredOrders"></installation-dashboard-table>
        <AppSpinner v-if="loadingSpinner " :loading="loadingSpinner" class="floating-spinner"/>
    </div>
</template>

<script>
import InstallationDashboardTable from '@/components/InstallerDashboard/InstallationDashboardTable.vue';
// import {sponsorshipStatuses, signageStatuses} from "../../constants"
import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
import Search from '@/components/Common/Search';
import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin';

export default {
    name: 'InstallerDashboardMainFeature',
    mixins: [DeepFilterMixin, InitializeOrderMixin],

    data: function() {
        return {
            orders: [],
            filteredOrders: [],
            loadingSpinner: false,
            showSidePanel: false,
            showTabletTopPanel: false,
            snackbar: {
                show: false,
                message: '',
                duration: 4000,
            },
        };
    },

    created() {

        this.filters = this.$store.getters['getVendorFilters'];
    },

    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('saveVendorFilters', this.filters);
        this.$store.dispatch('saveVendorOrders', this.filteredOrders);
        next();
    },

    components: {
        Search,
        InstallationDashboardTable,
    },

    methods: {

        inputSearch(searchStr) {

            let _this = this;

            setTimeout(function() {
                _this.filteredOrders = _this.orders.filter(x => _this.filterFunction(x, searchStr));
                _this.$forceUpdate();
            }, 1200);
        },

        showTabletModal() {

            this.showTabletTopPanel = true;
        },

        showMobileSideSearch() {

            this.showSidePanel = true;
        },

        setResultsOrders(orders) {


            let tempOrders = [];
            orders.forEach(el => tempOrders.push(this.initializeOrder(el)));

            console.log(tempOrders)

            this.orders = tempOrders;
            this.filteredOrders = tempOrders;
            this.showSidePanel = false;
        },

        loadingSearchResults(isLoading) {

            this.loadingSpinner = isLoading;
        },
    },

};
</script>

<style lang="scss" scoped>
@include scroller-styles;

.md-tabs {

    @include respond(xsmall) {
        width: calc(100% + 48px);
        transform: translateX(-24px);
    }
}

/deep/ .md-tabs-navigation .md-button {
    @include respond(small) {
        min-width: 48px;
    }
}

.filter-orders__field {
    width: 100%;
    max-width: 320px;
}

.vendor-dashboard__filters {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1.6rem 3.2rem;
    margin-bottom: 2rem;
    padding: 1rem 2.4rem 2.4rem;
    border: 2px solid $color-primary;
    border-radius: 5px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, .42);

    & > * {
        margin: 0;
    }
}

.component-wrap {
    width: 95%;
}

@include scroller-styles;

.boxes {
    width: 100%;
}

.filter-orders__field {
    width: 100%;
    max-width: 320px;
}

.init-search-tablet {
    padding-top: 10px;

    @include respond(small) {
        display: none;
    }

    @media only screen and (min-width: 1281px) {
        display: none;
    };

    .no-result-message {

        > p {
            padding-bottom: 25px;
            text-align: center;
            font-weight: 700;
            line-height: 20px;
            font-style: normal;
        }


    }

    .start-search-button {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        color: #000000 !important;
        background: rgba(231, 82, 82, 0.88) !important;
    }
}

.init-search-mobile {
    padding-top: 10px;
    display: none;

    @include respond(small) {
        display: flex;
    }

    .no-result-message {

        > p {
            padding-bottom: 25px;
            text-align: center;
            font-weight: 700;
            line-height: 20px;
            font-style: normal;
        }


    }

    .start-search-button {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        color: #000000 !important;
        background: rgba(231, 82, 82, 0.88) !important;
    }
}

.init-search-desktop {
    padding-top: 10px;

    .no-result-message {

        p {
            padding-top: 120px;
            text-align: center;
            font-weight: 700;
            line-height: 57px;
            font-style: normal;
            font-size: 48px;
        }
    }
}

.component-wrap {
    @include respond(small) {
        padding: 0px !important;
        margin: 0px !important;
        width: 100% !important;
    }
}

.main-hdr {
    margin-top: 10px;
}
</style>